import { AsyncModule } from "vuex-async-mutations";

import table from "@/store/modules/tables";

import columns from "@/apps/office/components/table/planning/capacity/columns";
import presets from "@/apps/office/components/table/planning/capacity/presets";
import renderers from "@/apps/office/components/table/shared/items/renderers";

export default {
  namespaced: true,

  async: true,

  modules: {
    settings: table("planning/forecast", presets, columns, renderers)
  },

  actionsAsync: {
    ["fetch:all"](context, type: string) {
      return this.$axios.get(`/projects/week-planning/${type}`, {
        params: { from: "2015-01-01" }
      });
    },

    ["fetch"](context, { id, type }: { id: string; type: string }) {
      return this.$axios.get(`/projects/${id}/week-planning/${type}`);
    },

    ["fetch:aggregate"](
      context,
      { ids, from, until }: { ids: string[]; from: string; until: string }
    ) {
      return this.$axios.post(`/projects/week-planning/aggregate`, {
        from,
        until,
        ids
      });
    },

    ["fetch:planned-and-unplanned"](
      context,
      { ids, from, until }: { ids: string[]; from: string; until: string }
    ) {
      return this.$axios.post(`/projects/week-planning/planned-and-unplanned`, {
        from,
        until,
        ids
      });
    }
  }
} as AsyncModule<any, any>;
