<template>
  <div
    class="d-flex justify-space-between align-center"
    :ref="`editablePeriod-${index}`"
    :class="`period-edit-${period.type}`"
    :style="gridColumnStyle"
  >
    <v-expand-transition>
      <div
        v-if="dragging"
        :class="`period-tooltip-${period.type}`"
        class="d-flex justify-space-between"
      >
        <div :class="`period-tooltip-week-${period.type}`">{{ start }}</div>
        <div v-if="start !== end" :class="`period-tooltip-week-${period.type}`">
          {{ end }}
        </div>
      </div>
    </v-expand-transition>

    <v-icon @mousedown="resize($event, 'left')" class="handle">
      mdi-drag-vertical-variant
    </v-icon>

    <div @mousedown="resize($event, 'middle')" class="drag"></div>

    <v-icon @mousedown="resize($event, 'right')" class="handle"
      >mdi-drag-vertical-variant</v-icon
    >
  </div>
</template>

<script>
import moment from "moment";
import _ from "underscore";

export default {
  inheritAttrs: true,
  props: {
    period: {
      type: Object
    },
    weeks: {
      type: Array
    },
    editablePeriods: {
      type: Array
    },
    index: {
      type: Number
    },
    container: {
      default: {}
    }
  },

  mounted() {
    this.setup();
  },

  data() {
    return {
      ref: "",
      gridArea: { start: null, end: null },
      dragging: false
    };
  },

  watch: {
    period() {
      this.setup();
    }
  },

  computed: {
    gridColumnStyle() {
      return {
        "grid-area": `1 / ${this.gridArea.start} / span 1 / ${this.gridArea.end}`
      };
    },

    start() {
      const week = this.weeks[this.gridArea.start - 1];

      return week ? week.number : 0;
    },

    end() {
      const week = this.weeks[this.gridArea.end - 2];

      return week ? week.number : 0;
    }
  },

  methods: {
    setup() {
      this.gridArea = { start: this.period.start, end: this.period.end };
      this.ref = this.$refs[`editablePeriod-${this.index}`];
    },

    base(e) {
      return {
        weeks: 0,
        start: e.clientX || 0,
        columnStart: Number(this.ref.style.gridColumnStart),
        columnEnd: Number(this.ref.style.gridColumnEnd),
        period: this.period,
        fixedWeeks: 0
      };
    },

    resize(e, position) {
      this.dragging = true;
      const updateGridArea = val => (this.gridArea = val);
      const resizePossible = val =>
        this.resizePossible(
          val,
          columnStart,
          columnEnd,
          position,
          this.period.type
        );
      const updateDates = () => this.updateDates(period, fixedWeeks, position);

      let {
        fixedWeeks,
        weeks,
        start,
        columnStart,
        columnEnd,
        period
      } = this.base(e);

      let startPosition;
      let endPosition;
      this.container.onmousemove = e => {
        weeks = Math.round((e.pageX - start) / 32);

        if (
          (position === "left" && !resizePossible(columnStart + weeks)) ||
          (position === "right" && !resizePossible(columnEnd + weeks)) ||
          (position === "middle" &&
            !resizePossible(columnStart + weeks) &&
            !resizePossible(columnEnd + weeks))
        ) {
          fixedWeeks = weeks;

          startPosition =
            position === "left" || position === "middle"
              ? columnStart + fixedWeeks
              : columnStart;

          endPosition =
            position === "right" || position === "middle"
              ? columnEnd + fixedWeeks
              : columnEnd;

          let gridArea = {
            start: Number(startPosition),
            end: Number(endPosition)
          };

          updateGridArea(gridArea);
        }
      };
      this.container.onmouseup = () => {
        this.container.onmousemove = this.container.onmouseup = null;

        this.dragging = false;
        updateDates();
      };
    },

    updateDates(periodToUpdate, weeks, position) {
      const updatedPeriods = this.editablePeriods.map(period => {
        if (period === periodToUpdate) {
          return {
            ...period,
            start:
              position === "left" || position === "middle"
                ? period.start + weeks
                : period.start,
            end:
              position === "right" || position === "middle"
                ? period.end + weeks
                : period.end
          };
        }

        return period;
      });

      this.$emit("update:editablePeriods", updatedPeriods);
    },

    resizePossible(weeks, columnStart, columnEnd, position, type) {
      const possibleStartWeeks = _.pluck(
        this.possiblePositions(this.editablePeriods, type),
        "start"
      ).filter(position => position >= columnEnd);
      const possibleEndWeeks = _.pluck(
        this.possiblePositions(this.editablePeriods, type),
        "end"
      ).filter(position => position <= columnStart);

      possibleStartWeeks.push(this.weeks.length + 1);
      possibleEndWeeks.push(1);

      switch (position) {
        case "left":
          return (
            this.minimumOfOneWeek(weeks, columnEnd, position) ||
            possibleEndWeeks.some(endDate => endDate > weeks)
          );
        case "right":
          return (
            this.minimumOfOneWeek(weeks, columnStart, position) ||
            possibleStartWeeks.some(startDate => startDate < weeks)
          );
        case "middle":
          return (
            possibleEndWeeks.some(endDate => endDate > weeks) ||
            possibleStartWeeks.some(startDate => startDate < weeks)
          );
      }
    },

    possiblePositions(editablePeriods, type) {
      return editablePeriods
        .filter(period => period.type != type)
        .map(period => ({
          start: period.start,
          end: period.end
        }));
    },

    minimumOfOneWeek(weeks, column, position) {
      const check = Math.abs(weeks - column) === 0;

      if (position === "left") {
        return check || weeks >= column;
      }

      if (position === "right") {
        return check || weeks <= column;
      }
    }
  }
};
</script>

<style lang="scss">
$periods: (
  "screening": #80cbc4,
  "conversion": #ce93d8,
  "planning": #e6b635,
  "installation": #42c5fe
);

@each $period, $color in $periods {
  .period-edit-#{$period} {
    height: 100%;
    border-radius: 8px !important;
    background-color: #{$color};
    position: relative;
    opacity: 0.9;
  }
}

@each $period, $color in $periods {
  .period-tooltip-#{$period} {
    position: absolute;
    background-color: transparent;
    height: 30px;
    width: 100%;
    bottom: 32px;
  }
}

@each $period, $color in $periods {
  .period-tooltip-week-#{$period} {
    border-radius: 8px !important;
    background-color: #{$color};
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.handle {
  min-width: 15px;
  max-width: 20px;
  width: 100%;
  cursor: col-resize;
}

.drag {
  cursor: move;
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: 0.2;
}
</style>
