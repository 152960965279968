










































































































































































































































import Vue from "vue";
import _ from "underscore";
import { ListItem } from "@/types";

export default Vue.extend({
  props: {
    filters: {
      type: Object as () => Record<string, boolean>
    },

    params: {
      type: Object as () => Record<string, any>
    },

    readonly: {
      type: Object as () => Record<string, true>
    },

    limit: {
      type: Object as () => Record<string, any>
    },

    setParam: {
      type: Function
    },

    clearParam: {
      type: Function
    }
  },

  computed: {
    projectTypes(): ListItem[] {
      const states: ListItem[] = this.$t(
        "office.dictionaries.projectTypes"
      ) as any;

      if (!this.limit.type) return states;

      return states
        .filter(state => this.limit.type.includes(state.value))
        .map(state => ({
          ...state,
          disabled:
            this.params.type?.length === 1 &&
            this.params.type.includes(state.value)
        }));
    },

    installationTypes(): ListItem[] {
      let types: ListItem[] = this.$t(
        "office.dictionaries.installationTypes"
      ) as any;

      if (!this.limit.installationType) return types;

      return types
        .filter(type => this.limit.installationType?.includes(type.value))
        .map(type => ({
          ...type,
          disabled: true
        }));
    },

    housingCooperatives(): unknown[] {
      return _.sortBy(
        this.$store.state.dictionaries.housingCooperatives.items,
        "name"
      );
    },

    projectManagers(): unknown[] {
      return _.sortBy(
        this.$store.state.dictionaries.projectManagers.items,
        "name"
      );
    },

    accountManagers(): unknown[] {
      return _.sortBy(
        this.$store.state.dictionaries.accountManagers.items,
        "name"
      );
    },

    installers(): unknown[] {
      return _.sortBy(this.$store.state.dictionaries.installers.items, "name");
    },

    zonmaatCustomers(): unknown[] {
      const items = (this.$t(
        "office.dictionaries.zonmaatCustomers"
      ) as unknown) as { text: string; value: string }[];
      return items.map(({ text, value }) => ({ id: value, name: text }));
    },

    zonmaatProjectManagers(): unknown[] {
      const zmPms = _.sortBy(
        this.$store.state.dictionaries.zonmaatProjectManagers.items,
        "name"
      );
      zmPms.push({
        id: "no-value",
        name: this.$t("office.labels.unassigned")
      });
      return zmPms;
    },

    zonmaatPlanners(): unknown[] {
      const zmPlanners = _.sortBy(
        this.$store.state.dictionaries.zonmaatPlanners.items,
        "name"
      );
      zmPlanners.push({
        id: "no-value",
        name: this.$t("office.labels.unassigned")
      });
      return zmPlanners;
    },

    zonmaatForemans(): unknown[] {
      const zmForemans = _.sortBy(
        this.$store.state.dictionaries.zonmaatForemans.items,
        "name"
      );
      zmForemans.push({
        id: "no-value",
        name: this.$t("office.labels.unassigned")
      });
      return zmForemans;
    },

    projectStates(): ListItem[] {
      let states: ListItem[] =
        this.$store.state.route.name != "monitoringonlyInstallation"
          ? this.$t("office.dictionaries.projectStates")
          : (this.$t("office.dictionaries.moProjectStates") as any);

      if (!this.limit.projectStates) return states;

      return states
        .filter(state => this.limit.projectStates.includes(state.value))
        .map(state => ({
          ...state,
          disabled:
            this.params.projectStates?.length === 1 &&
            this.params.projectStates.includes(state.value)
        }));
    }
  }
});
